import { createContext, FC, useContext, useState } from "react";

import { UserInfo } from "../@types";

interface User {
  user: UserInfo | undefined;
  setUser: (user: UserInfo) => void;
}

const InitialUser: User = {
  user: {
    company: {
      id: "",
      name: "",
    },
    user: {
      login: "",
      type: "",
    },
    branch: {
      id: "",
    },
    session: "",
  },
  setUser: () => {},
};

const UserContext = createContext(InitialUser);

export const UserProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [user, setUser] = useState<UserInfo>();
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
