import React from "react";
import { useTranslation } from "react-i18next";

import { dsLogoPlain } from "@/image";
import styles from "./Unauthorized.module.scss";

const Unauthorized: React.FC = () => {
  const { t } = useTranslation("session");
  return (
    <div className={`${styles.wrapper} flex-center`}>
      <div className={styles.statusWrapper}>
        <span className={styles.status}>404</span>
      </div>
      <h1>{t("incorrect")}</h1>
      <h4>{t("login")}</h4>
      <button className={styles.button}>
        <img
          src={dsLogoPlain}
          width={16}
          height={16}
          alt="logo-plain"
        />
        {t("go")}
      </button>
    </div>
  );
};

export default Unauthorized;
