import "./index.scss";

import { AxiosError } from "axios";
import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { toast } from "react-toastify";

import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

import App from "./App";
import { UserProvider } from "./hooks/useUserInfo";
import de from "./locales/de/translation.json";
import en from "./locales/en/translation.json";
import reportWebVitals from "./reportWebVitals";

i18next.init({
  fallbackLng: "en",
  lng: "de",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    de: de,
    en: en,
  },
});
const onError = (error: AxiosError<unknown, any>) => {
  if (error.response?.status === 401 || error.response?.status === 404) {
    window.location.href = "/unauthorized";
  } else if (error.response?.status === 500) {
    toast.error(
      "Ein interner Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut."
    );
  }
};
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError,
  }),
  mutationCache: new MutationCache({
    onError,
  }),
});
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <QueryClientProvider client={queryClient}>
          <UserProvider>
            <App />
          </UserProvider>
      </QueryClientProvider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
