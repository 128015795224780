import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import { Suspense } from "react";
import { ToastContainer } from "react-toastify";

import LoadingComponent from "@/components/shared/loading-component";
import { router } from "./routes";
import { ANTD_CONFIG_OVERRIDE } from "@/utils";

function App() {
  return (
    <div className="App">
      <ConfigProvider {...ANTD_CONFIG_OVERRIDE}>
        <Suspense fallback={<LoadingComponent />}>
          <RouterProvider
            router={router}
            fallbackElement={<LoadingComponent />}
          />
        </Suspense>
      </ConfigProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
