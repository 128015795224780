import { Outlet } from "react-router-dom";

// import Header from "../header/header";
import styles from "./Layout.module.scss";

const Layout = () => {
  return (
    <div className={styles.appWrapper}>
      {/* <Header /> */}
      <div className={styles.body}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
