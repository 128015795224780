import React from "react";
import { createBrowserRouter } from "react-router-dom";

import Layout from "./components/shared/layout";
import Protected from "./pages/Protected";
import Start from "./pages/start";
import Unauthorized from "./pages/unauthorized";

const Report = React.lazy(() => import("./pages/audit-report"));
const Rental = React.lazy(() => import("./pages/rental"));

export const router = createBrowserRouter([
  {
    path: "/",
    id: "root",
    Component: Layout,
    children: [
      {
        Component: Protected,
        children: [
          {
            path: "/",
            Component: Rental,
          },
          {
            path: "/report/:roomId/add",
            Component: Report,
          },
          {
            path: "/report/:roomId/:reportId",
            Component: Report,
          },
        ],
      },
      {
        path: "start",
        id: "start",
        Component: Start,
      },
      {
        path: "unauthorized",
        id: "unauthorized",
        Component: Unauthorized,
      },
    ],
  },
]);
