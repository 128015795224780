import { RoomSearchParams, dsRoom } from "../@types";
import instance from "../utils/axios";

export const getRooms = async (
  dsCompanyId: string,
  search: RoomSearchParams,
  branchId?: string
) => {
  let url = `/rooms?companyId=${dsCompanyId}`;
  if (search.name) {
    url += `&name=${search.name}`;
  }
  if (search.type) {
    url += `&type=${search.type}`;
  }
  if (search.hasReport) {
    url += `&hasReport=${search.hasReport}`;
  }
  if (branchId) {
    url += `&branchId=${branchId}`;
  }
  const res = await instance.get<{ data: dsRoom[] }>(url);
  return res.data.data;
};

export const getRoomById = async (dsRoomId: number): Promise<dsRoom> => {
  const res = await instance.get<{ data: dsRoom }>(`/rooms/${dsRoomId}`);
  return res.data.data;
};

export const syncRooms = async (dsCompanyId: string, branchId?: string) => {
  let url = `/rooms/sync?companyId=${dsCompanyId}`;
  if (branchId) {
    url += `&branchId=${branchId}`;
  }
  return instance.post(url);
};
