import { Circles } from "react-loader-spinner";

import styles from "./LoadingComponent.module.scss";
import { FC } from "react";

const LoadingComponent: FC<{ title?: string }> = ({ title }) => {
  return (
    <div className={`${styles.spinner}`}>
      <Circles
        height="100"
        width="100"
        color="#004750"
        ariaLabel="circles-loading"
        visible={true}
        wrapperClass={styles.wrapperClass}
      />
      {title ? <h4 className={styles.loadingMessage}>{title}</h4> : null}
    </div>
  );
};
export default LoadingComponent;
