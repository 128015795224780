import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoadingComponent from "@/components/shared/loading-component";
import { useQueryParam } from "@/hooks/useQueryParam";
import { useUser } from "@/hooks/useUserInfo";
import { validateSessionId } from "@/services/auth";
import { syncRooms } from "@/services/room";
import { trackEvents } from "@/utils";
import { useMutation, useQuery } from "@tanstack/react-query";

const Start: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("session");
  const { setUser } = useUser();
  const sessionId = useQueryParam("wes");
  const companyId = useQueryParam("companyId") || undefined;
  const branchId = useQueryParam("branchId") || undefined;

  const { data, isLoading } = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      if (!sessionId) {
        return navigate("/unauthorized");
      }
      const {
        data: { data },
      } = await validateSessionId(sessionId);
      const userInfo = {
        ...data,
        session: sessionId,
      };
      if (userInfo.user.type === "root") {
        if (companyId) {
          userInfo.company.id = companyId;
        } else if (branchId) {
          userInfo.branch = {
            id: branchId,
          };
        }
      }
      if (branchId && userInfo.user.type === "company") {
        userInfo.branch = {
          id: branchId,
        };
      }
      return userInfo;
    },
  });
  const { mutate, isPending } = useMutation({
    mutationFn: (companyId: string, branchId?: string) =>
      syncRooms(companyId, branchId),
    onSuccess: () => {
      navigate("/");
      trackEvents("Room", "Sync", "Sync room data");
    },
    onError: (error) => {
      localStorage.removeItem("session-id");
      navigate("/unauthorized");
    },
  });
  useEffect(() => {
    if (data && sessionId) {
      localStorage.setItem("session-id", sessionId);
      setUser(data);
      if (data.branch) {
        localStorage.setItem("branch-id", data.branch.id);
      } else {
        localStorage.removeItem("branch-id");
      }
      // @ts-ignore
      mutate(data.company.id, branchId);
    }
  }, [data, mutate, sessionId, branchId, setUser]);
  if (isLoading || isPending) {
    return <LoadingComponent title={t("syncing")} />;
  }
  return null;
};

export default Start;
