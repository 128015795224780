import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { UserInfo } from "@/@types";
import LoadingComponent from "@/components/shared/loading-component";
import { useUser } from "@/hooks/useUserInfo";
import { validateSessionId } from "@/services/auth";

const Protected = () => {
  const navigate = useNavigate();
  const { user, setUser } = useUser();
  const sessionId = localStorage.getItem("session-id");
  const branchId = localStorage.getItem("branch-id");
  const { data, isFetched } = useQuery({
    queryKey: ["user"],
    enabled: !user,
    queryFn: async (): Promise<UserInfo | null> => {
      if (!sessionId) {
        return null;
      }
      const res = await validateSessionId(sessionId);
      const company: UserInfo = {
        ...res.data.data,
        session: sessionId,
      };
      if (branchId) {
        company.branch = {
          id: branchId,
        };
      }
      return company;
    },
  });
  useEffect(() => {
    if (!data && isFetched) {
      localStorage.removeItem("session-id");
      localStorage.removeItem("branch-id");
      navigate("/unauthorized");
    }
    if (data) {
      setUser(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched, sessionId]);
  if (!user) {
    return <LoadingComponent />;
  }
  return <Outlet />;
};

export default Protected;
