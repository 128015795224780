// Transform empty space to camel case: for example => forExample
export const toCamelCase = (input: string) => {
  return input.replace(/(\s+\w)/g, (match) => match.charAt(1).toUpperCase());
};

export const capitalize = (s: string): string => {
  return s && s[0].toUpperCase() + s.slice(1);
};

export const trackEvents = (
  category: string,
  action: string,
  eventName: string
) => {
  if (window._paq) {
    window._paq.push(["trackEvent", category, action, eventName]);
  }
};
