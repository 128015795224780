import axios from "axios";

const instance = axios.create({
  baseURL: `/api/v2`,
});

instance.interceptors.request.use((config) => {
  const sessionId = localStorage.getItem("session-id");
  const branchId = localStorage.getItem("branch-id");
  config.headers["session-id"] = sessionId;
  if (branchId) {
    config.headers["branch-id"] = branchId;
  }
  return config;
});

export default instance;
