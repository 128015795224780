import { ConfigProviderProps } from "antd/es/config-provider";

export const REPORT_YEARS = [2023, 2022] as const;
export const ADD_NEW_REPORT = "add-new-report";

export const ANTD_CONFIG_OVERRIDE: ConfigProviderProps = {
  theme: {
    token: {
      colorPrimary: "#004750",
    },
  },
  button: {
    style: {
      borderRadius: "15px",
      boxShadow: "none",
    },
  },
};
